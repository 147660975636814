<template>
  <toolbar title="Auftragsdokumente" />
  <div class="text-center w-full">
    <div
      class="p-2 md:p-4 w-full md:w-1/2 text-left inline-block bg-white rounded-lg"
    >
      <div v-if="currentJob">
        <span class="font-bold text-lg block"
          >Auftrag: {{ currentJob.Vertragsbeschreibung }}</span
        >
        <span class="block"
          >Einsatzort: {{ currentJob.Einsatzort_Beschreibung }}</span
        >
        <span class="block">Datum: {{ currentJob.Datum_von }}</span>
        <span class="block"
          >Uhrzeit: {{ currentJob.Soll_Von }} - {{ currentJob.Soll_Bis }}</span
        >
        <span class="font-bold text-lg block">Dokumente</span>
        <div v-if="documents.length == 0">
          <span class="font-bold">Aktuell sind keine Dokumente vorhanden</span>
        </div>
        <div v-else>
          <div v-for="document in documents" :key="document">
            <div
              @click="viewDocument(document)"
              class="border-b border-black p-2 cursor-pointer"
            >
              <span class="font-bold">{{ document.type }}</span>
              <i
                v-if="document.signed"
                class="fas fa-eye fa-xl float-right"
              ></i>
              <i v-else class="fas fa-pen fa-xl float-right"></i>
            </div>
          </div>
        </div>
        <span class="font-bold text-lg block mt-4">Neues Dokument anlegen</span>

        <div
          v-if="!this.existingDocuments.includes('Schichtnachweis')" 
          @click="addDocument('Schichtnachweis')"
          class="border-b border-black p-2 cursor-pointer"
        >
          <span class="font-bold">Schichtnachweis</span>
          <i class="fas fa-plus fa-xl float-right"></i>
        </div>
        <div
          v-if="!this.existingDocuments.includes('Schichtprotokoll')"
          @click="addDocument('Schichtprotokoll')"
          class="border-b border-black p-2 cursor-pointer"
        >
          <span class="font-bold">Schichtprotokoll</span>
          <i class="fas fa-plus fa-xl float-right"></i>
        </div>
        <div
          v-if="!this.existingDocuments.includes('Schlüssel- und Materialübergabe')"

          @click="addDocument('Schlüssel- und Materialübergabe')"
          class="border-b border-black p-2 cursor-pointer"
        >
          <span class="font-bold">Schlüssel- und Materialübergabe</span>
          <i class="fas fa-plus fa-xl float-right"></i>
        </div>
        <div v-if="this.existingDocuments.includes('all')">
          <b>Es wurden bereits alle Dokumente erstellt!</b>
        </div>
      </div>
      <div v-else>
        <span class="text-center font-bold w-full text-xl mt-10 inline-block"
          >Du hast aktuell keine laufenden Aufträge. Um Dokumente für einen
          Auftrag einzusehen, wähle den Auftrag aus dem Archiv aus.</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import Toolbar from "@/components/Toolbar.vue";
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: { Toolbar },
  data() {
    return {
      stats: {},
      documents: [],
      currentJob: false,
      existingDocuments: []
    };
  },
  methods: {
    addDocument(type) {
      window.location =
        "/jobs/" + this.currentJob.id + "/documents/add?type=" + type;
    },
    viewDocument(document) {
      if (document.signed) {
        window.location =
          "/jobs/" + this.currentJob.id + "/documents/" + document.id;
      } else {
        window.location =
          "/jobs/" + this.currentJob.id + "/documents/" + document.id + "/edit";
      }
    },
    getJobDocuments() {
      CASP.getJobDocuments(this.currentJob, (status, response) => {
        this.documents = response;
        this.documents.forEach((d) => {
          this.existingDocuments.push(d.type);
        });
        if (this.existingDocuments.includes("Schichtnachweis")  && this.existingDocuments.includes("Schichtprotokoll") && this.existingDocuments.includes("Schlüssel- und Materialübergabe")) {
          this.existingDocuments.push("all");
        }
      });
    },
    getCurrentJob() {
      let vm = this;
      if (this.$route.params.jobid) {
        CASP.getJobById("", this.$route.params.jobid, (response) => {
          if (!response) return;
          vm.currentJob = response;
          vm.getJobDocuments();
        });
      } else if (this.$route.query.job) {
        CASP.getJobById("", this.$route.query.job, (response) => {
          if (!response) return;
          vm.currentJob = response;
          vm.getJobDocuments();
        });
      } else {
        CASP.getStats(this.token, (status, response) => {
          if (status == 200) {
            vm.stats = JSON.parse(response);
            if (vm.stats.currentJob) {
              vm.currentJob = vm.stats.currentJob;
              vm.getJobDocuments();
            }
          }
        });
      }
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    this.getCurrentJob();
  },
};
</script>