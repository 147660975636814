<template>
  <Toolbar title="Auftragseinstellungen" backUrl="/admin"></Toolbar>
  <div
    v-if="jobs != null"
    class="fixed w-full h-full top-0 left-0 bg-black/50 text-center z-40"
  >
    <div
      class="mt-10 inline-block w-full md:w-auto bg-white rounded-lg p-4 relative"
    >
      <div
        style="position: absolute; right: 40px; color: black"
        class="cursor-pointer"
        @click="jobs = null"
      >
        <i ref="xmark" class="fa fa-xmark text-4xl"></i>
      </div>
      <span class="text-2xl font-bold">Protokolle</span>
      <div class="w-full text-left overflow-x-auto">
        <table class="max-h-96 max-w-full overflow-auto">
          <thead>
            <tr>
              <th>Datum</th>
              <th>Start</th>
              <th>Ende</th>
              <th>Mitarbeiter</th>
              <th>Rundgänge</th>
              <th class="text-center">Aktionen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="job in jobs" :key="job.id">
              <td>{{ job.Datum_von }}</td>
              <td>{{ job.Soll_Von }}</td>
              <td>{{ job.Soll_Bis }}</td>
              <td>{{ job.user }}</td>
              <td>{{ job.finishedTours }}/{{ job.requiredTours }}</td>
              <td><button @click="downloadJob(job)">PDF</button></td>
            </tr>
            <tr v-if="jobs.length == 0" class="col-span-6 text-center">
              <td colspan="6">Keine Rundgänge gefunden</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="w-full text-left overflow-x-auto">
    <span class="font-bold text-xl">Offene Dokumente</span>
    <table>
      <thead>
        <tr>
          <th>Vertrag</th>
          <th>Standort</th>
          <th>Typ</th>
          <th>Datum</th>
          <th>Verfasser</th>
          <th class="text-center">Aktionen</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="document in openDocuments" :key="document.id">
          <td>{{ document.jobdata.Vertragsbeschreibung }}</td>
          <td>{{ document.jobdata.Einsatzort_Beschreibung }}</td>
          <td>{{ document.type }}</td>
          <td>{{ document.document.date }}</td>
          <td>{{ document.document.author.name }}</td>
          <td>
            <button
              class="bg-gray-500 text-white py-2 px-4 rounded-lg font-bold"
              @click="viewDocument(document)"
            >
              Anzeigen
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="w-full text-left">
    <span class="font-bold text-xl">Filtern nach Vertrag</span>
    <div class="mt-2">
      <label class="font-bold mr-3">Objekt-Filter:</label>
      <input
        v-model="filter"
        class="border-b-2 w-64 mr-2 p-2 border-gray-500"
        placeholder="Filter"
      />
      <div class="inline-block cursor-pointer pt-2" @click="reset()">
        <i class="!inline-block fa fa-xmark text-xl align-middle"></i>
      </div>
    </div>
    <div class="relative w-full xl:inline-block">
      <div
        class="inline-block px-3 py-2 rounded-lg shadow-lg m-1 font-bold cursor-pointer"
        :class="
          selectedContract == contract
            ? '!bg-caspyellow'
            : contract.documents || contract.routes
            ? 'bg-green-200'
            : 'bg-white'
        "
        v-for="contract in contracts"
        :key="contract.code"
        @click="selectContract(contract)"
      >
        {{ contract.name }}
      </div>
    </div>
    <div v-if="selectedContract">
      <span class="text-xl font-bold block"
        >Standorte für {{ selectedContract.name }}:</span
      >
      <div class="relative w-full xl:inline-block">
        <div
          class="inline-block px-3 py-2 rounded-lg shadow-lg m-1 font-bold cursor-pointer bg-white"
          :class="
            selectedLocation == location
              ? '!bg-caspyellow'
              : location.documents || location.routes
              ? '!bg-green-200'
              : '!bg-white'
          "
          v-for="location in selectedContract.locations"
          :key="location.id"
          @click="selectLocation(location)"
        >
          {{ location.name }}
        </div>
      </div>

      <div v-if="selectedLocation">
        <div class="w-full mt-3 bg-white p-2 rounded-lg overflow-x-auto">
          <div class="cursor-pointer inline-block mr-5">
            <span
              class="inline-block font-bold border-b-2 border-black text-xl"
              :class="{
                '!border-caspyellow': selectedTab == 'Dokumente',
              }"
              @click="selectedTab = 'Dokumente'"
              >Dokumente</span
            >
          </div>
          <div class="cursor-pointer inline-block mr-5">
            <span
              class="inline-block font-bold border-b-2 border-black text-xl"
              :class="{
                '!border-caspyellow': selectedTab == 'Rundgänge',
              }"
              @click="selectedTab = 'Rundgänge'"
              >Rundgänge</span
            >
          </div>
          <div
            class="cursor-pointer inline-block"
            v-show="
              stats && stats.permissions && stats.permissions.internalDispo
            "
          >
            <span
              class="inline-block font-bold border-b-2 border-black text-xl"
              :class="{
                '!border-caspyellow': selectedTab == 'Zugriffsrechte',
              }"
              @click="selectedTab = 'Zugriffsrechte'"
              >Zugriffsrechte</span
            >
          </div>
          <div
            v-if="selectedTab == 'Dokumente'"
            class="relative w-full xl:inline-block"
          >
            <div v-if="locationInfo.documentsEnabled">
              <label class="font-bold mr-4">Tag:</label>
              <input
                type="date"
                v-model="selectedDate"
                @change="getLocationInfo()"
              />
              <button
                class="float-right text-white font-bold bg-red-500"
                @click="setDocuments(false)"
              >
                Dokumente deaktivieren
              </button>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Erstellt</th>
                      <th>Dokument</th>
                      <th>Verfasser</th>
                      <th class="text-center">Aktionen</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="document in locationInfo.docs"
                      :key="document.id"
                    >
                      <td>
                        {{ new Date(document.createdAt).toLocaleDateString() }}
                      </td>
                      <td>{{ document.type }}</td>
                      <td>{{ document.document.author.name }}</td>
                      <td class="text-center">
                        <button class="m-1" @click="viewDocument(document)">
                          Ansehen
                        </button><button class="m-1" @click="deleteDocument(document)">
                          Löschen
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else>
              <button class="mt-4 bg-green-400" @click="setDocuments(true)">
                Dokumente aktivieren
              </button>
            </div>
          </div>
          <div
            v-else-if="selectedTab == 'Zugriffsrechte'"
            class="relative w-full xl:inline-block"
          >
            <div class="relative">
              <table class="w-full min-w-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>E-Mail</th>
                    <th>Erteilt von</th>
                    <th>Kommentar</th>
                    <th class="text-center">Aktionen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="permission in locationInfo.permissions"
                    :key="permission.id"
                  >
                    <td>
                      <input
                        v-if="permission.user.id == -1"
                        class="border-b-2"
                        v-model="permission.user.name"
                      /><span v-else>{{ permission.user.name }}</span>
                    </td>
                    <td>
                      <input
                        v-if="permission.user.id == -1"
                        class="border-b-2"
                        v-model="permission.user.mail"
                      /><span v-else>{{ permission.user.mail }}</span>
                    </td>
                    <td>{{ permission.createdBy }}</td>
                    <td>
                      <input class="border-b-2" v-model="permission.comment" />
                    </td>
                    <td class="text-center">
                      <button
                        class="m-1"
                        v-if="permission.user.id != -1"
                        @click="deleteExternalDocumentPermission(permission)"
                      >
                        Löschen
                      </button>
                      <button
                        class="m-1"
                        @click="updateDocumentUser(permission)"
                      >
                        Speichern
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else-if="selectedTab == 'Rundgänge'">
            <span class="text-xl font-bold block mt-2"
              >Einsatzstellen für {{ selectedLocation.name }}:</span
            >
            <div class="relative w-full xl:inline-block">
              <div
                class="inline-block px-3 py-2 rounded-lg shadow-lg m-1 font-bold cursor-pointer bg-white"
                :class="{
                  '!bg-caspyellow': selectedPlace == place,
                  '!bg-green-200': place.routes,
                }"
                v-for="place in selectedLocation.places"
                :key="place.id"
                @click="selectPlace(place)"
              >
                {{ place.name }}
              </div>
            </div>
            <div v-if="selectedPlace">
              <span class="text-2xl font-bold inline-block mt-4 mr-2"
                >Routen</span
              ><button @click="createRoute()">Erstellen</button>
              <button class="m-1" @click="viewJobsWithTours()">
                Dokumentation anzeigen
              </button>
              <table>
                <thead>
                  <th>Name</th>
                  <th>Beschreibung</th>
                  <th>Status</th>
                  <th>Aktionen</th>
                </thead>
                <tbody>
                  <tr v-for="route in routes" :key="route.id">
                    <td>{{ route.name }}</td>
                    <td>{{ route.description }}</td>
                    <td>{{ route.active ? "aktiv" : "inaktiv" }}</td>
                    <td class="text-center">
                      <button class="m-1" @click="viewRoute(route)">
                        Bearbeiten
                      </button>
                    </td>
                  </tr>
                  <tr v-if="routes.length == 0">
                    <td colspan="3" class="text-center">
                      Keine Routen gefunden
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from "@/CASP.js";
import Toolbar from "@/components/Toolbar.vue";

export default {
  name: "AdminJobSettings",
  data() {
    return {
      allContracts: {},
      contracts: {},
      routes: [],
      filter: "",
      jobs: null,
      selectedContract: null,
      selectedLocation: null,
      selectedPlace: null,
      selectedPlaceCode: null,
      selectedDate: new Date().toISOString().substr(0, 10),
      openDocuments: [],
      stats: {},
      selectedTab: "Dokumente",
      locationInfo: {},
    };
  },
  components: { Toolbar },

  methods: {
    reset() {
      this.filter = "";
      this.selectedContract = null;
      this.selectedLocation = null;
      this.selectedPlace = null;
      this.selectedPlaceCode = null;
      this.selectedDate = new Date().toISOString().substr(0, 10);
      this.routes = [];
      this.selectedTab = "Dokumente";
      this.locationInfo = {};
      if (window.localStorage) {
        localStorage.removeItem("selectedContract");
        localStorage.removeItem("selectedLocation");
        localStorage.removeItem("selectedPlace");
      }
    },
    viewRoute(route) {
      window.location = "/admin/routes/" + route.id;
    },
    setDocuments(value) {
      CASP.updateLocation(
        this.selectedContract.code,
        this.selectedLocation.code,
        { documentsEnabled: value },
        (status, res) => {
          this.getLocationInfo();
        }
      );
    },
    viewJobsWithTours() {
      this.jobs = [];
      CASP.getJobsWithTours(this.selectedPlace.code, (status, response) => {
        if (status == 200) {
          this.jobs = response;
        }
      });
    },
    createRoute() {
      window.location = "/admin/routes/create?place=" + this.selectedPlace.code;
    },
    getContracts() {
      CASP.getPresets("", (presets) => {
        this.allContracts = presets.contracts;
        delete this.allContracts[""];
        this.contracts = this.allContracts;
        if (window.localStorage) {
          if (localStorage.getItem("selectedContract")) {
            let preselectedContract = this.allContracts.filter((x) =>
              x.code.includes(localStorage.getItem("selectedContract"))
            );
            if (preselectedContract.length > 0) {
              this.selectContract(preselectedContract[0], true);
            }
          }
        }
      });
    },
    viewDocument(document) {
      window.open("/jobs/" + document.job + "/documents/" + document.id);
    },
    deleteDocument(document) {
      if (confirm("Soll das Dokument wirklich gelöscht werden?")) {
        CASP.deleteJobDocument(document.id, (status, response) => {
          if (status == 200) {
            window.location.reload();
          }
        });
      }
    },
    getStats() {
      CASP.getStats("", (status, response) => {
        if (status == 200) {
          this.stats = JSON.parse(response);
        }
      });
    },
    selectContract(contract, initial = false) {
      this.filter = contract.name;
      this.selectedContract = contract;

      if (window.localStorage) {
        localStorage.setItem("selectedContract", contract.code);
        if (initial) {
          if (localStorage.getItem("selectedLocation")) {
            let preselectedLocation = this.selectedContract.locations.filter(
              (x) => x.code.includes(localStorage.getItem("selectedLocation"))
            );
            if (preselectedLocation.length > 0) {
              this.selectLocation(preselectedLocation[0]);
            }
          }
        }
      }
    },
    getOpenDocuments() {
      CASP.getOpenDocuments((status, res) => {
        if (status == 200) {
          this.openDocuments = res.filter((x) => x.jobdata !== null);
        }
      });
    },
    selectPlace(place) {
      this.selectedPlace = place;
      this.getRoutesForPlace();
    },
    getRoutesForPlace() {
      CASP.getRoutesForPlace(this.selectedPlace.code, (status, res) => {
        this.routes = res;
      });
    },

    updateDocumentUser(permission) {
      permission.location = this.selectedLocation;
      permission.contract = this.selectedContract;
      CASP.updateDocumentUser(permission, (status, res) => {
        if (status == 200) {
          this.getLocationInfo();
        }
      });
    },
    deleteExternalDocumentPermission(user) {
      CASP.deleteExternalDocumentPermission(user, (status, res) => {
        if (status == 200) {
          this.getLocationInfo();
        }
      });
    },
    selectLocation(location) {
      this.selectedLocation = location;
      if (window.localStorage) {
        localStorage.setItem("selectedLocation", location.code);
      }
      this.getLocationInfo();
    },
    getLocationInfo() {
      if (!this.selectedContract || !this.selectedLocation) return;
      CASP.getLocationInfo(
        this.selectedContract.code,
        this.selectedLocation.code,
        this.selectedDate,
        (status, res) => {
          this.locationInfo = res;
          this.locationInfo.permissions.push({
            user: {
              id: -1,
              name: "",
              mail: "",
            },
            comment: "",
            createdBy: "",
          });
        }
      );
    },
    filterContracts() {
      this.contracts = this.allContracts.filter((x) =>
        x.name.toLowerCase().includes(this.filter.toLowerCase())
      );
    },
    downloadJob(job) {
      CASP.getAdminOTL((status, res) => {
        if (status == 200) {
          window.open(
            process.env.VUE_APP_API_URL +
              "/routes/jobs/" +
              job.id +
              "/download?otl=" +res.otl+"&assets",
            "_blank"
          );
        }
      });
    },
  },
  watch: {
    filter: function () {
      this.filterContracts();
    },
  },
  created: function () {
    this.getStats();
    this.getContracts();
    this.getOpenDocuments();
    document.getElementById("mainspinner").style.display = "none";
  },
};
</script>