<template>
  <Transition>
    <TopBanner :mode="bannerMode" :text="bannerText" v-if="bannerText" />
  </Transition>
  <div class="w-full h-full relative text-center m-0 p-0">
    <div class="fixed w-full z-10 text-center">
      <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
      <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
    </div>
    <div class="relative w-full h-full text-center overflow-y-auto">
      <div
        class="relative w-full text-center pt-48 sm:pt-[10%] xl:w-5/6 xl:inline-block"
      >
        <button
          class="hidden md:inline absolute left-0 text-black bg-caspyellow border-4 border-gray-500 rounded-lg w-32 py-2 font-bold"
          onclick="window.location='/admin'"
        >
          Zurück
        </button>
        <span class="font-bold text-4xl">Mitarbeiterverwaltung</span><br />

        <button
          class="inline md:hidden text-black bg-caspyellow border-4 border-gray-500 rounded-lg mt-5 w-32 py-2 font-bold"
          onclick="window.location='/admin'"
        >
          Zurück
        </button>
        <div class="hidden">
          <input
            ref="fileinput"
            id="fileinput"
            type="file"
            accept=".jpg, .jpeg"
            @change="prepareUploadFile()"
          />
        </div>
        <div class="mt-2">
          <label class="font-bold mr-3">Nach Namen suchen:</label>
          <input
            v-model="filter"
            class="border-b-2 w-64 mr-2 p-2 border-gray-500"
            placeholder="Filter"
            @input="applyFilter()"
          />
          <div
            class="inline-block cursor-pointer pt-2"
            @click="
              filter = '';
              applyFilter();
            "
          >
            <i class="!inline-block fa fa-xmark text-xl align-middle"></i>
          </div>
        </div>
        <div v-if="filteredUsers.length > 0" id="tablecontent" class="w-full">
          <table class="w-full overflow-x-auto block md:table">
            <thead>
              <th>Personalnummer</th>
              <th>Vorname</th>
              <th>Nachname</th>
              <th>IHK</th>
              <th>Bewacher-PDF</th>
              <th>Qualifikation</th>
              <th>Funktion</th>
              <th>ID-BEWAR</th>
              <th>Ausweisnummer</th>
              <th>QR-Text</th>
              <th>Bild</th>
            </thead>
            <tbody>
              <template v-for="user in filteredUsers" :key="user.persnr">
                <tr>
                  <td class="font-bold">{{ user.persnr }}</td>
                  <td>{{ user.name }}</td>
                  <td>{{ user.surname }}</td>
                  <td class="text-center" :class="user.ihk?'bg-green-300':'bg-red-300'"><input @change="changeUser(user)" type="checkbox" :id="user.persnr+'_ihk'"  v-model="user.ihk" /><label :for="user.persnr+'_ihk'"></label></td>
                  <td class="text-center" :class="user.bewacherpdf?'bg-green-300':'bg-red-300'"><input @change="changeUser(user)" type="checkbox" :id="user.persnr+'_bewacherpdf'"  v-model="user.bewacherpdf" /><label :for="user.persnr+'_bewacherpdf'"></label></td>
                  <td>{{ user.Qualifikation }}</td>
                  <td>{{ user.function }}</td>
                  <td>{{ user.idbewar }}</td>
                  <td>{{ user.idnr }}</td>
                  <td>{{ user.qrValue }}</td>
                  <td>{{ user.pictureName }}</td>
                </tr>
                <div class="align-top w-full flex flex-row">
                  
                  <button
                    v-if="user.pictureId"
                    class="inline-block font-bold mx-1 mt-4 bg-gray-500 rounded p-2 text-white whitespace-nowrap"
                    @click="uploadPicture(user)"
                  >
                    Bild ändern
                  </button>
                  <button
                    v-if="user.pictureId && !show[user.pictureId]"
                    class="inline-block font-bold mx-1 mt-4 bg-gray-500 rounded p-2 text-white whitespace-nowrap"
                    @click="showPicture(user)"
                  >
                    Bild anzeigen
                  </button>
                  <img class="max-h-36" v-else :src="show[user.pictureId]" />
                  <button
                    v-if="!user.pictureId"
                    class="inline-block font-bold mx-1 mt-4 bg-gray-500 rounded p-2 text-white whitespace-nowrap"
                    @click="uploadPicture(user)"
                  >
                    Bild hochladen
                  </button>
                  <button
                    v-if="user.phone"
                    class="inline-block font-bold mx-1 mt-4 bg-red-500 rounded p-2 text-white whitespace-nowrap"
                    @click="sendInvitation(user)"
                  >
                    Zugangsdaten per SMS
                  </button>
                </div>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CASP from "../../CASP";
import { useCookies } from "vue3-cookies";
import JobModal from "@/components/JobModal.vue";
import Helpers from "../../Helpers";
import TopBanner from "@/components/TopBanner";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "Jobs",
  components: { JobModal, TopBanner },
  data() {
    return {
      token: "",
      allUsers: [],
      filteredUsers: [],
      show: [],
      uploadingUser: {},
      bannerText: "",
      bannerMode: "success",
      filter: "",
    };
  },
  methods: {
    getUsers() {
      let vm = this;
      CASP.getUsers(this.token, (res) => {
        if (res) {
          vm.allUsers = res;
          vm.filteredUsers = res;
        }
      });
    },
    applyFilter(user) {
      this.filteredUsers = this.allUsers.filter((el) => {
        return (el.name + el.surname + el.persnr + el.idnr)
          .toLowerCase()
          .includes(this.filter.toLowerCase());
      });
    },
    showPicture(user) {
      CASP.getPicture(this.token, user, (res) => {
        if (res) {
          this.show[user.pictureId] = res.image;
        }
      });
    },
    changeUser(user) {
      CASP.changeUser(this.token, user.id, user, (res) => {
        if (res) {
          this.getUsers();
        }
      });
    },
    sendInvitation(user) {
      let vm = this;
      CASP.sendInvitation(this.token, user.id, (res) => {
        if (res) {
          vm.bannerText = "SMS mit Zugangsdaten gesendet";
          setTimeout(() => {
            vm.bannerText = "";
          }, 3000);
        }
      });
    },
    prepareUploadFile() {
      let vm = this;
      let fr = new FileReader();
      let file = this.$refs.fileinput.files[0];
      fr.readAsArrayBuffer(file);
      fr.onload = function () {
        CASP.savePicture(
          vm.token,
          vm.uploadingUser,
          {
            data: Helpers.arrayBufferToBase64(fr.result),
            fileName: file.name,
          },
          (res) => {
            vm.uploadingUser.pictureId = res.id;
            vm.uploadingUser.pictureName = file.name;
            vm.show[res.id] = res.image;
          }
        );
      };
    },
    uploadPicture(user) {
      this.uploadingUser = user;
      this.$refs.fileinput.click();
    },
    filterUsers(user) {
      this.showUserDropdown = user;
      let options = "";
      this.allUsers.forEach((el) => {
        if (
          el.displayName.toLowerCase().includes(user.displayName.toLowerCase())
        ) {
          options += "<p class='dp'>" + el.displayName + "</p>";
        }
      });
      if (this.$refs["userDropdown"]) {
        this.$refs["userDropdown"].innerHTML = options;
      }
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (this.cookies.get("authtoken")) {
      this.token = this.cookies.get("authtoken");
      CASP.isAuthenticated(this.token, (auth) => {
        if (!auth) {
          window.location = "/admin/login";
        } else {
          this.getUsers();
        }
      });
    } else {
      window.location = "/admin/login";
    }
  },
};
</script>
<style scoped></style>