<template>
  <Toolbar title="Externer Bereich" />
  <div
    v-if="selectedJob != null"
    class="fixed w-full h-full top-0 left-0 bg-black/50 text-center z-40"
  >
    <div
      class="mt-10 inline-block w-full md:w-auto bg-white rounded-lg p-4 relative"
    >
      <div
        style="position: absolute; right: 40px; color: black"
        class="cursor-pointer"
        @click="selectedJob = null"
      >
        <i ref="xmark" class="fa fa-xmark text-4xl"></i>
      </div>
      <span class="text-2xl font-bold">Dokumente</span>
      <table class="max-h-96 overflow-y-auto">
        <thead>
          <tr>
            <th>Typ</th>
            <th class="text-center">Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in selectedJob.docs" :key="document.id">
            <td>{{ document.type }}</td>
            <td>
              <button
                v-if="document.signatureMissing"
                class="bg-red-500"
                @click="showDocument(document)"
              >
                Unterschreiben</button
              ><button v-else @click="showDocument(document)">Ansehen</button>
            </td>
          </tr>
          <tr
            v-if="selectedJob.docs.length == 0"
            class="col-span-6 text-center"
          >
            <td colspan="6">Keine Dokumente gefunden</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="bg-white p-4">
    <div class="cursor-pointer inline-block mr-5">
      <span
        class="inline-block font-bold border-b-2 border-black text-xl"
        :class="{
          '!border-caspyellow': selectedTab == 'docs',
        }"
        @click="selectedTab = 'docs'"
        >Dokumente</span
      >
    </div>
    <div class="cursor-pointer inline-block mr-5">
      <span
        class="inline-block font-bold border-b-2 border-black text-xl"
        :class="{
          '!border-caspyellow': selectedTab == 'scans',
        }"
        @click="selectedTab = 'scans'"
        >Wegpunktscans</span
      >
    </div>
    <div v-if="selectedTab == 'docs'">
      <span class="font-bold text-2xl">Schichten mit Dokumenten</span>
      <div>
        <span class="font-bold">Startdatum der Schicht: </span>
        <input type="date" v-model="filterDate" @change="filterDocuments()"/>
      </div>
      <table>
        <thead>
          <tr>
            <th>Vertrag</th>
            <th>Standort</th>
            <th>Einsatzstelle</th>
            <th>Datum</th>
            <th>Uhrzeit</th>
            <th class="text-center">Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="jobs.length == 0">
            <td colspan="6">Keine Dokumente gefunden</td>
          </tr>
          <tr v-for="job in jobs" :key="job.id">
            <td>{{ job.Vertragsbeschreibung }}</td>
            <td>{{ job.Einsatzort_Beschreibung }}</td>
            <td>{{ job.Einsatzstelle_Beschreibung }}</td>
            <td>{{ job.Datum_von }}</td>
            <td>{{ job.Uhrzeit }}</td>

            <td>
              <button
                v-if="!job.signatureMissing"
                class="bg-gray-500 text-white py-2 px-4 rounded-lg font-bold"
                @click="selectedJob = job"
              >
                Dokumente anzeigen
              </button>
              <button
                v-else
                class="bg-red-500 text-white py-2 px-4 rounded-lg font-bold"
                @click="selectedJob = job"
              >
                Dokumente unterschreiben
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="selectedTab == 'scans'">
      <span class="font-bold text-2xl">Letzte Scans</span>
      <table>
        <thead>
          <tr>
            <th>Vertrag</th>
            <th>Standort</th>
            <th>Einsatzstelle</th>
            <th>Person</th>
            <th>Datum</th>
            <th>Uhrzeit</th>
            <th>Beschreibung</th>
            <th>Code</th>
            <th>Typ</th>
            <th>Kommentar</th>
            <th>Aktion</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="scan in scans" :key="scan.id">
            <td>{{ scan.contract }}</td>
            <td>{{ scan.location }}</td>
            <td>{{ scan.place }}</td>
            <td>{{ scan.person }}</td>
            <td>{{ scan.date }}</td>
            <td>{{ scan.time }}</td>
            <td>{{ scan.name }}</td>
            <td>{{ scan.code }}</td>
            <td>{{ scan.type }}</td>
            <td>{{ scan.comment }}</td>
            <td>
              <button @click="showDoc(scan.job)">Protokoll</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import CASP from "@/CASP.js";
import Toolbar from "@/components/Toolbar.vue";
import Spinner from "@/components/Spinner";

export default {
  name: "AdminDocuments",
  data() {
    return {
      jobs: [],
      scans: [],
      selectedTab: "docs",
      selectedJob: null,
      filterDate: "",
    };
  },

  components: { Toolbar, Spinner },
  methods: {
    showDoc(job) {
      window.open(
        process.env.VUE_APP_API_URL + "/routes/jobs/" + job + "/download"
      );
    },
    filterDocuments(){
      this.getJobsForExternalUsers();
    },
    showDocument(document) {
      if (document.type == "Rundgang") {
        window.open(
          process.env.VUE_APP_API_URL +
            "/routes/jobs/" +
            this.selectedJob.id +
            "/download"
        );
      } else {
        window.open(
          "/jobs/" +
            this.selectedJob.id +
            "/documents/" +
            document.id +
            "?close=true"
        );
      }
    },
    getJobsForExternalUsers() {
      document.getElementById("mainspinner").style.display = "inline-block";
      CASP.getJobsForExternalUsers(this.filterDate, (status, res) => {
        if (status == 200) {
          this.jobs = res;
        }
        document.getElementById("mainspinner").style.display = "none";
      });
    },
    getLastScans() {
      CASP.getLastScansForExternalUsers((status, res) => {
        if (status == 200) {
          this.scans = res;
        }
      });
    },
  },
  created: function () {
    this.getJobsForExternalUsers();
    this.getLastScans();
  },
};
</script>