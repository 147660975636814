<template>
  <toolbar
    title="Wächter-Kontrollgang"
    :showReload="true"
    @reload="reloadAll()"
    :custom="true"
    @custom="
      $router.push({
        name: 'Dashboard',
      })
    "
  />
  <div v-if="oldAppError" class="w-full text-center">
    <span class="w-3/4 bg-red-400 font-bold p-10 rounded-xl mt-10 inline-block"
      >Deine App ist zu alt, um diese Funktion zu nutzen. Bitte lade dir die
      neuste Version der App hier herunter:<br />
      <a
        href="https://trit-solutions.com/caspapps/"
        class="!text-white !mt-4 inline-block"
        >Neue Version</a
      ></span
    >
  </div>
  <div v-if="stats.currentJob" class="md:p-4">
    <div v-if="stats.currentJob" class="text-center">
      <span class="font-bold w-full text-2xl"
        >{{ stats.currentJob.Einsatzstelle_Beschreibung }} ({{
          stats.currentJob.Datum_von
        }})</span
      >
    </div>
    <div class="w-full text-left overflow-x-auto">
      <template v-for="route in stats.currentJob.routes" :key="route">
        <span class="font-bold text-xl mt-4 inline-block">{{
          route.name
        }}</span>
        <table class="!mt-0">
          <thead>
            <th>Nr.</th>
            <th>Beginn</th>
            <th>Ende</th>
            <th>Status</th>
            <th>Aktionen</th>
          </thead>
          <tbody>
            <tr v-for="(tour, index) in route.tours" :key="tour">
              <td>{{ index + 1 }}</td>
              <td>{{ tour.startedAt }}</td>
              <td>{{ tour.finishedAt }}</td>
              <td>
                ({{ tour.waypointscans.length }} / {{ route.waypoints.length }})
              </td>
              <td>
                <span v-if="tour.otherUser" class="text-red-500 font-bold"
                  >Tour von {{ tour.otherUser }} gestartet.</span
                >
                <button
                  v-else-if="tour.finishedAt && tour.local"
                  @click="continueTour(route, index)"
                >
                  Synchronisieren</button
                ><button
                  v-else-if="
                    (!tour.finishedAt && !hasRunningTour) ||
                    hasRunningTour == tour.id
                  "
                  @click="continueTour(route, index)"
                >
                  Fortsetzen
                </button>
                <span
                  v-else-if="!tour.finishedAt"
                  class="text-red-500 font-bold"
                  >Deine vorherige Tour muss erst abgeschlossen werden</span
                >
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
import toolbar from "@/components/Toolbar.vue";
import CASP from "../../CASP";
export default {
  name: "EWKSList",
  components: {
    toolbar,
  },
  data() {
    return {
      stats: {},
      window: window,
      oldAppError: false,
      hasRunningTour: false,
      tours: [],
    };
  },
  methods: {
    reloadRoutes() {
      CASP.getRoutesForJob(this.$route.params.jobid, (status, response) => {
        if (status == 200) {
          this.stats.currentJob.routes = response;
          localStorage.setItem("stats", JSON.stringify(this.stats));
        }
      });
    },
    reloadAll(notify = null) {
      CASP.getStats("", (status, response) => {
        this.getStats();
        console.log(notify);
        if (notify) {
          notify();
        }
      });
    },
    getStats() {
      if (localStorage && localStorage.getItem("stats")) {
        this.stats = JSON.parse(localStorage.getItem("stats"));
        this.routes = this.stats.currentJob.routes;
        this.routes.forEach((route) => {
          while (route.tours.length < route.toursPerShift) {
            route.tours.push({
              route: route.id,
              index: route.tours.length + 1,
              waypointscans: [],
            });
          }
        });
        if (localStorage.getItem("local")) {
          let local = JSON.parse(localStorage.getItem("local"));
          if (local.tours) {
            local.tours.forEach((tour) => {
              tour.local = true;
              if (this.routes.map((x) => x.id).includes(parseInt(tour.route))) {
                this.routes.find((x) => x.id == tour.route).tours[
                  tour.index - 1
                ] = tour;
              }
            });
          }
          if (local.waypointscans) {
            for (let i = 0; i < local.waypointscans.length; i++) {
              let route = this.routes.find(
                (x) => x.id == local.waypointscans[i].route
              );
              if (route) {
                route.tours[
                  local.waypointscans[i].tourIndex - 1
                ].waypointscans.push(local.waypointscans[i]);
              }
            }
          }
        }
        this.hasRunningTour = false;
        this.routes.forEach((route) => {
          route.tours.forEach((tour) => {
            if (tour.startedAt && !tour.finishedAt && !tour.otherUser) {
              this.hasRunningTour = tour.id;
            }
          });
        });
        if (!this.stats.currentJob) {
          this.$router.push({ name: "Dashboard" });
        }
      }
    },
    continueTour(route, index) {
      this.reloadAll(() => {
        if (
          this.stats &&
          this.stats.currentJob &&
          this.stats.currentJob.routes
        ) {
          let statRoute = this.stats.currentJob.routes.find(
            (r) => r.id == route.id
          );
          let tourToStart=false
          if (statRoute.tours.length >= index) {
            tourToStart = statRoute.tours[index];
            index = tourToStart.index-1;
          }

          if (this.hasRunningTour) {
            if (tourToStart && this.hasRunningTour != tourToStart.id) return;
            if (!tourToStart) return;
          }
          if (tourToStart && tourToStart.otherUser) {
            return;
          }
          this.$router.push({
            name: "Tour",
            params: {
              jobid: this.$route.params.jobid,
              routeid: route.id,
              index: index + 1,
            },
          });
        } else {
          this.$router.push({ name: "Dashboard" });
        }
      });
    },
    getRoutes() {
      CASP.getRoutes(this.token, (status, response) => {});
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    if (!localStorage) {
      this.oldAppError = true;
      return;
    }
    this.getStats();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
