<template>
  <div class="w-full h-full relative text-center m-0 p-0">
    <div class="fixed w-full z-10 text-center">
      <img class="w-full lg:mt-24 inline sm:hidden" src="@/assets/banner.jpg" />
      <img class="w-full sm:inline hidden" src="@/assets/DesktopBanner.png" />
    </div>
    <div class="relative w-full h-full text-center overflow-y-auto pb-14">
      <Spinner v-if="loading" />

      <div
        class="relative w-full text-center pt-[37%] sm:pt-[10%] xl:w-1/2 xl:inline-block"
      >
        <div v-if="offline" class="pb-4 px-4">
          <span
            class="px-2 py-1 bg-red-500 rounded inline-block font-bold underline "
            
            >Du bist aktuell offline, der Funktionsumfang der App ist
            eingeschränkt.</span
          ><br />
          <button class="bg-caspyellow text-black mt-2" @click="getStats()">
            Verbinden
          </button>
        </div>
        <div v-if="stats.currentJob" class="pb-4 px-4">
          <span
            class="px-2 py-1 bg-caspyellow rounded inline-block font-bold underline cursor-pointer"
            @click="
              $router.push({
                name: 'Jobdetails',
                params: { jobid: stats.currentJob.id },
              })
            "
            >Zum aktuellen Auftrag:
            {{ stats.currentJob.Vertragsbeschreibung }}</span
          >
        </div>
        <div
          v-if="!offline"
          class="dashboardtile"
          onclick="window.location='/jobs'"
        >
          <i class="far fa-clipboard fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg">Aufträge</span>
          <span
            v-if="stats.jobs > 0"
            class="bg-red-500 rounded-full absolute p-1 -top-2 -right-2"
            >{{ stats.jobs }}</span
          >
        </div>
        <div
          v-if="
            !offline && stats.permissions && stats.permissions.internalDispo
          "
          class="dashboardtile"
          onclick="window.location='/tasks'"
        >
          <i class="fa fa-list-check fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg">Aufgaben</span>
          <span
            v-if="stats.tasks > 0"
            class="bg-red-500 rounded-full absolute p-1 -top-2 -right-2"
            >{{ stats.tasks }}</span
          >
        </div>
        <div
          v-if="!offline"
          class="dashboardtile"
          onclick="window.location='/chat'"
          :class="{ 'md:hidden': !stats.advanced }"
        >
          <i class="far fa-comment fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg"
            >Nachrichten</span
          >
          <span
            v-if="stats.messages > 0"
            class="bg-red-500 rounded-full absolute p-1 -top-2 -right-2"
            >{{ stats.messages }}</span
          >
        </div>
        <div
          v-if="!offline"
          class="dashboardtile"
          onclick="window.location='/archive'"
        >
          <i class="fa fa-business-time fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg">Archiv</span>
        </div>
        <div
          v-if="!offline"
          class="dashboardtile"
          onclick="window.location='/documents'"
        >
          <i class="far fa-folder fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg">Dokumente</span>
        </div>
        <div
          v-if="!offline && stats.type == 'internal' && stats.advanced"
          class="dashboardtile md:hidden"
          onclick="window.location='/admin/login'"
        >
          <i class="fa-brands fa-internet-explorer fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg">Web-Login</span>
        </div>
        <div
          v-if="!offline && stats.advanced"
          class="dashboardtile xl:w-96 w-full"
          onclick="window.location='/admin'"
        >
          <i class="fa fa-sliders fa-2x pt-2" /><br />
          <span class="inline-block pb-2 align-middle text-lg"
            >Admin-Bereich</span
          >
        </div>
        <br />
        <button
          v-if="!offline"
          class="md:hidden mt-10 border-caspyellow text-black bg-caspyellow rounded-lg w-28 xl:w-56 xs:w-72 py-2 border-2 font-bold xl:m-10"
          onclick="window.location='/ID'"
        >
          Dienstausweis
        </button>
        <a
          href="tel:+492233402850"
          class="md:hidden mt-10 rounded-lg w-28 xl:w-56 xs:w-72 py-2 inline-block font-bold border-2 text-white xl:m-10"
          style="background: #ff3a35; border: #ff3a35"
        >
          Rufbereitschaft
        </a>
        <div v-if="!offline" class="w-full text-center hidden md:inline-block">
          <button
            class="mt-5 rounded-lg w-28 xl:w-56 bg-red-500 xs:w-72 py-2 font-bold border-2 text-white xl:m-10"
            @click="logoutWeb()"
          >
            Ausloggen
          </button>
        </div>
      </div>
    </div>
  </div>
  <span class="fixed bottom-0 right-0 p-2 text-gray-400 text-sm z-30">V4.5</span>
  <div
    class="md:hidden bg-gray-50 border-t border-t-gray-300 fixed bottom-0 w-full text-center xl:hidden"
    onclick="window.location='/settings'"
  >
    <span class="inline-block py-2 text-iosblue font-medium"
      >Einstellungen</span
    >
  </div>
</template>
<script>
import CASP from "../CASP";
import Toolbar from "@/components/Toolbar.vue";
import Spinner from "@/components/Spinner";

import { useCookies } from "vue3-cookies";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      window: window,
      stats: {},
      offline: false,
      advanced: false,
      loading: false,
    };
  },
  components: { Toolbar, Spinner },
  methods: {
    logoutWeb() {
      this.cookies.remove("authtoken");
      CASP.logoutWeb((status, response) => {
        window.location = "/login";
      });
    },
    getStats() {
      this.loading = true;
      CASP.getStats(this.token, (status, response) => {
        this.loading = false;
        if (status == 401) {
          window.location = "/forbidden";
        } else if (status == 200) {
          this.offline = false;
          this.stats = JSON.parse(response);
          if (this.stats.type == "external") {
            this.window.location = "/admin";
          }
          this.advanced = this.stats.advanced;
        } else {
          // get stats from local
          if (localStorage && localStorage.getItem("stats")) {
            this.stats = JSON.parse(localStorage.getItem("stats"));
            this.offline = true;
          } else {
            window.location = "/forbidden";
          }
        }
      });
    },
  },
  created: function () {
    document.getElementById("mainspinner").style.display = "none";
    this.token = this.cookies.get("authtoken");
    this.getStats();
  },
};
</script>
